<template>
    <OMediaQueryProvider>
        <OMatchMedia v-slot="{ isMobileOrTablet }">
            <template v-if="isMobileOrTablet">
                <ODataLookup :dataObject="dsOrgUnits" v-bind="$attrs, $props" ref="dropdownEl" :title="$t('Org Unit')">
                    <template #target="{ target }">
                        <slot name="target" :target="target">
                            <component v-if="is && !readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue" readonly></component>
                            <component v-if="is && readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue" readonly></component>
                            
                            <input v-else-if="textInput && !readOnly" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
                            <input v-else-if="textInput && readOnly" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
                            
                            <span v-else :ref="target" style="cursor:pointer;">
                                <slot name="orgunit">{{ OrgUnit }}</slot>
                            </span>
                        </slot>
                    </template>
                    <template #toolbarActions>
                        <OToolbarAction :text="$t('Restrict to context')" v-model="_restrictToContext" />
                    </template>
                    <OColumn field="OrgUnit" width="350" sortable></OColumn>
                    <OColumn field="UnitType" :headerName="$t('Type')" width="120" sortable></OColumn>
                    <OColumn field="Parent" width="200" sortable></OColumn>
                </ODataLookup>
            </template>
            <template v-else>
                <DataLookupDropdown :allwaysOpened="props.autoOpened" :dataLookupControl="lookupControl" @onopen="onOpen" ref="dropdownEl">
                    <template #target="{ target }">
                        <slot name="target" :target="target">
                            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
                            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
                            <span v-else :ref="target" style="cursor:pointer;">
                                <slot name="orgunit">{{ OrgUnit }}</slot>
                            </span>
                        </slot>
                    </template>
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button @click="activeTab = 'grid'" class="nav-link active"  data-bs-toggle="tab" :data-bs-target="'#org-units-list' + lookupId" type="button" role="tab"><small>{{$t('Org Units List')}}</small></button>
                            <button @click="loadTree" class="nav-link" data-bs-toggle="tab" :data-bs-target="'#org-units-structure' + lookupId" type="button" role="tab"><small>{{$t('Org Structure')}}</small></button>
                            <div v-if="activeTab === 'grid' || activeTab === 'tree'" class="ms-auto mt-1 hstack gap-1">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" :id="'restrict-to-context-' + lookupId" type="checkbox" v-model="_restrictToContext">
                                    <label class="form-check-label" :for="'restrict-to-context-' + lookupId">
                                        {{$t("Restrict to context")}}
                                    </label>
                                </div>
                                <div v-if="includeClosed" class="form-check form-check-inline">
                                    <input class="form-check-input" :id="'IncludeClosed' + lookupId" type="checkbox" v-model="_includeClosed">
                                    <label class="form-check-label" :for="'IncludeClosed' + lookupId">
                                        {{$t("Include closed")}}
                                    </label>
                                </div>
                                <slot name="toolbarActions"></slot>
                            </div>
                        </div>
                    </nav>
                    <div class="tab-content d-flex" style="min-height: 0; flex-grow: 1;" ref="tabContent">
                        <div class="tab-pane fade show active" :id="'org-units-list' + lookupId" role="tabpanel"  tabindex="0">
                            <div class="d-flex h-100" ref="lookupContainer">
                                <DataLookupList :dataLookupControl="lookupControl" ref="lookupRef" />
                            </div>
                        </div>
                        <div class="tab-pane fade w-100" :id="'org-units-structure' + lookupId" role="tabpanel"  tabindex="0">
                            <div class="d-flex flex-column h-100" :style="{ 'width': width }">
                                <!-- <OrgunitsTree @nodeClick="onOrgUnitTreeItemClicked" :whereClause="whereClause"/> -->
                                <ODataGrid :dataObject="dsOrgUnitsTree" :nodeData="treeConfig" hideGridMenu hideActionColumn hideMultiselectColumn :rowclickhandler="onOrgUnitTreeItemClicked"
                                    disableNavigation :multiselect="multiselect" :rowClass="(row) => row.Closed != null ? 'text-decoration-line-through' : undefined">
                                    <o-column field="ID" width="80" hide></o-column>
                                    <o-column field="UnitType" width="80" hide></o-column>                                    
                                </ODataGrid>
                            </div>
                        </div>
                    </div>        
                </DataLookupDropdown>
            </template>
        </OMatchMedia>
    </OMediaQueryProvider>    
</template>

<script>

export default defineComponent({
    inheritAttrs: false
});
</script>

<script setup>
import { OMediaQueryProvider, OMatchMedia } from 'o365-ui-components';
import { InjectionKeys } from 'o365-utils';
import { alert } from 'o365-vue-services';
import { ref, reactive, computed, defineProps, defineExpose, watch, onBeforeUnmount, defineComponent, inject, onMounted } from 'vue';
import { DataLookupDropdown, DataLookupList, DataLookupControl } from 'o365-datalookup';
//import OrgunitsTree from 'o365.vue.components.OrgunitsTree.vue';
import { context, getOrCreateProcedure } from 'o365-modules';
import 'o365-nodedata';

const props = defineProps({
    bind: {
        type: Function,
        required: true
    },
    id: {
        type: String,
        required: false
    },
    whereClause: {
        type: String,
        required: false,        
    },
    allowClear: {
        type: Boolean,
        default: true
    },
    textInput: {
        type: Boolean,
        default: false
    },
    restrictToContext: {
        type: Boolean,
        default: true,
    },
    value: null,
    modelValue: null,
    is: String,
    multiselect: {
        type: Boolean,
        default: false
    },
    blockedContent: { // Table name
        type: String,
        required: false,
        default: ""
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    },
    includeClosed: {
        type: Boolean,
        required: false,
        default: false
    },
    
    autoOpened: {
        type: Boolean,
        required: false,
        default: false
    }
});

// Make the whereClause prop reactive
const propWhereClause = computed(() => props.whereClause);

const lookupRef = ref(null);
const OrgUnit = ref(null);
const tabContent = ref(null);
const lookupContainer = ref(null);
const width = ref("400px");
// TODO: Optimize for grids by injecting unique column id if lookup id isn't provided
const lookupId = props.id ?? crypto.randomUUID();
const dropdownEl = ref(null);
const activeTab = ref("grid");
let treeInitialized = false;

const gridEditorApi = inject(InjectionKeys.dataGridEditorCellControlKey, null);


/** Value shown on the input when the selector is in textInput mode */
const textInputValue = computed(() => {
    return props.modelValue ?? props.value ?? OrgUnit.value
});

// Context
const _restrictToContext = ref(props.restrictToContext);
const _includeClosed = ref(props.includeClosed);
const currentContextIdPath = ref(context.idPath);

const procAddOrgUnitToPinnedList = getOrCreateProcedure({
    id:"procAddOrgUnitToPinnedList",
    procedureName: "sstp_System_LookupSelections",
});

// Refresh data source if any of the following refs are changed
watch([_restrictToContext, _includeClosed, propWhereClause, currentContextIdPath, activeTab], () => {
    if (activeTab.value === 'grid') {
        dsOrgUnits.load();
    } else {
        treeInitialized = false;
        dsOrgUnitsTree.recordSource.whereClause = buildWhereClause()
        dsOrgUnitsTree.load();
        loadTree();
    }
});

const nonContextOrgUnitDataObjectConfig = {
    viewName: 'stbv_System_OrgUnits',
    loadRecents: false,
    fields: [
        { name: 'PrimKey' },
        { name: 'ID', type: 'number' },
        { name: 'Name' },
        { name: 'Title' },
        { name: 'OrgUnit' },
    ]
};

const orgUnitDataObjectConfig = {    
    viewName: 'sviw_System_OrgUnits',
    loadRecents: true,
    distinctRows: true,
    maxRecords: 25,
    fields: [
        { name: "PrimKey", type: "string" },
        { name: "ID", type: "number" },
        { name: "IdPath", type: "string" },
        { name: "OrgUnit", type: "string" },
        { name: "Closed", type: "date" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "Domain_ID", type: "number" },
        { name: "Level", type: "number" },
        { name: "UnitType", type: "string" },
        { name: "NamePath", type: "string", sortOrder: 1, sortDirection: "asc" },
        { name: "Parent", type: "string" },
        { name: "AccessIdPath", type: "string" },
        { name: "Company_ID", type: "number" },
        { name: "ExtendedTableName", type: "string" }
    ]
};

const dsOrgUnits = $getOrCreateDataObject({
    ...orgUnitDataObjectConfig,
    id: 'o_dsOrgUnits' + lookupId,
});

// Create a slightly modified copy of dsOrgUnits so that we can use it in the tree
const dsOrgUnitsTree = $getOrCreateDataObject({    
    ...orgUnitDataObjectConfig,
    id: 'o_dsOrgUnits' + crypto.randomUUID(),
    selectFirstRowOnLoad: false,
    loadRecents: false,    
    whereClause: buildWhereClause()
});

const treeConfig = {
    displayField: 'OrgUnit',
    getDisplay: row => row.OrgUnit ?? row.Name ?? row.Title,
    column: {
        headerName: 'Org Unit',
        boldDisplay: true
    }
};
function getPercentageOfInteger(integer, percent) {
  const result = (integer * percent) / 100;
  return Math.round(result); // Returns an integer by rounding to the nearest whole number
}
function getColumns(){
    if (props.autoOpened) {
        let returnFields = [];
        returnFields.push({ field: "OrgUnit", width: getPercentageOfInteger(window.innerWidth,51 ) }, { field: "UnitType", width: getPercentageOfInteger(window.innerWidth,17 ) }, { field: "Parent", width: getPercentageOfInteger(window.innerWidth, 29) })
        return returnFields
    }
    else {
        return [{ field: "OrgUnit", width: 350 }, { field: "UnitType", width: 120 }, { field: "Parent", width: 200 }];
    }
}
function getHeight(){
    if (props.autoOpened) {
        return window.innerHeight
    }
    else {
        return window.innerHeight > 800 ? 500 : 300;
    }
}
const lookupControl = reactive(new DataLookupControl({
    dataObject: dsOrgUnits,
    noClear: !props.allowClear,
    multiselect: props.multiselect,
    bind: org => {
        if (props.bind) {
            props.bind(org)
        } else {
            OrgUnit.value = org.OrgUnit;
        }
    },
    columns: getColumns(),
    height:  getHeight(),
    filterRow: true,
    rowClass: row => row.Closed != null ? 'text-decoration-line-through' : undefined,
}));

// Context change listener
const onContextChangedCT = context.on("Change", ({ idPath }) => {
    currentContextIdPath.value = idPath;
});

// Set whereClause in the BeforeLoad event
const onBeforeLoadOrgUnitsCT = dsOrgUnits.on('BeforeLoad', (options) => {
    options.whereClause = buildWhereClause();
});

function buildWhereClause(opts = {}) {
    let baseWhereClause = propWhereClause.value ? "(" + propWhereClause.value + ")" : "";
    const clauses = [baseWhereClause];

    if (_restrictToContext.value && currentContextIdPath.value && !opts.skipContextFiltering) {
        clauses.push(`IdPath LIKE '${currentContextIdPath.value}%'`);
    }

    if (props.blockedContent) {
        clauses.push(`NOT ISNULL(BlockedContent,'') LIKE '%${props.blockedContent}%'`);
    }

    if(!_includeClosed.value) {
        clauses.push("Closed IS NULL");
    }

    return clauses.filter(x => x).join(" AND ");
}

async function onOrgUnitTreeItemClicked(selected) {
    if(!props.autoOpened){
    dropdownEl.value.dropdown.close(); // We have to manually close the dropdown

    }
    props.bind(selected.item);

    try {
        await procAddOrgUnitToPinnedList.execute({
            Pinned: null,
            Record_ID: selected.item.ID,
            ViewName: 'sviw_System_OrgUnits',
        });

        dsOrgUnits.load();
    } catch(e) {
        alert($t('Unable to pin selection.'), 'warning', { autohide: true, duration: 3000 });
    }
}

const onOpen = () => {
    function outputsize() {
        if (tabContent.value && lookupContainer.value.offsetWidth) {
            width.value = lookupContainer.value.offsetWidth + "px";
        }
    }
    new ResizeObserver(outputsize).observe(tabContent.value)
}

function activateEditor() {

    dropdownEl.value?.dropdown?.open?.call();
}

onMounted(() => {
    if (gridEditorApi) {
        gridEditorApi.activate();
    }
    if(props.autoOpened){
        activateEditor()
    }
});

onBeforeUnmount(() => {
    if (onContextChangedCT) {
        onContextChangedCT();
    }

    if (onBeforeLoadOrgUnitsCT) {
        onBeforeLoadOrgUnitsCT();
    }
});

const loadNonContextUnit = async (_pData, pNode) => {
    try {

        const dsNonContextOrgUnits = getOrCreateDataObject({
            ...nonContextOrgUnitDataObjectConfig,
            id: 'o_dsNonContextOrgUnits' + lookupId,
        })
        const id = +pNode.key.split('/').at(-1);
        const item = await dsNonContextOrgUnits.recordSource.refreshRowById(id, {
            returnExisting: true
        });
        return item.item;
    } catch (ex) {
        return Promise.resolve({
            Name: $t('unknown'),
        });
    }
}


function loadTree() {
    activeTab.value = "tree";
    if (treeInitialized) {
        return;
    }

    dsOrgUnitsTree.nodeData.enable();
    dsOrgUnitsTree.nodeData.addConfiguration({ type: 'hierarchy', idPathField: 'IdPath', requireParents: false, getSummaryItem: loadNonContextUnit });
    dsOrgUnitsTree.nodeData.init();
    treeInitialized = true;
}

defineExpose({ activateEditor });    
</script>

<style scoped>
    .o365-data-lookup .o365-data-grid.o365-root{
        margin: 0px!important;
    }
    .o365-data-grid.o365-root{
        border-radius:0
    }
    .o365-data-grid .o365-body-row{
        cursor:pointer;
    }
    .o365-data-lookup{
        padding: 5px;
    }

    .nav-link.active{
        background-color: var(--bs-light-bg-subtle)!important;
    }
</style>
